<script lang="ts">
	import { override } from '$lib/utils/classnames'
	import { twMerge } from 'tailwind-merge'
	interface Props {
		class?: string
		children?: import('svelte').Snippet
	}

	let { class: className = '', children }: Props = $props()
</script>

<div
	class={twMerge(
		override(
			'sticky top-0 z-30 max-w-full w-full bg-white dark:bg-brand-gray-6 dark:lg:bg-brand-gray-5',
			className,
		),
	)}
>
	<div class="relative">
		<div
			class="h-[2px] w-10 rounded-full bg-brand-gray-4/50 mx-auto absolute lg:hidden top-[8px] right-[calc(50%-20px)]"
		></div>

		<div class="flex justify-between items-center py-4 px-4 lg:pt-6">
			{@render children?.()}
		</div>
	</div>
</div>
