<script lang="ts">
	interface Props {
		hasPadding?: boolean
		children?: import('svelte').Snippet
	}

	let { hasPadding = true, children }: Props = $props()
</script>

<div class={`w-full min-h-[100dvh] lg:min-h-[calc(100vh-72px)] ${hasPadding ? 'pb-24' : ''}`}>
	{@render children?.()}
</div>
